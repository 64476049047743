import React from "react";
import eye from "../assets/images/eye.png";
import xray from "../assets/images/xray.png";
import wallet from "../assets/images/wallet.png";
import dashboard from "../assets/images/dashboard.png";

const Features = () => {
  return (
    <>
      <section className="py-5" id="features">
        <div className="text-center mb-5">
          <p className="subtitle">Features</p>
          <h2>Key VisionScan Features</h2>
        </div>
        <div className="container">
          <div className="row g-3 mt-5">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img src={eye} width="100%" alt="..." />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">EYE</h4>
                      <p className="mt-1 text-break">
                        With advanced algorithms and machine learning
                        capabilities, "Eye" offers a comprehensive view of
                        market trends and portfolio performance, helping users
                        to stay ahead of the competition.
                      </p>
                      <p className="mt-1 text-break">
                        To learn more about the Eye tool and see Eye user
                        interface, please refer to the{" "}
                        <a href="https://docs.visionscan.ai/key-visionscan-ai-tool-features/market-analysis-tool-eye">Eye page located in the whitepaper</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img src={xray} width="100%" alt="..." />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">X-Ray</h4>
                      <p className="mt-1 text-break">
                        Whether you are a seasoned investor or a newcomer to the
                        crypto world, "X-Ray" is an indispensable tool that will
                        help you to identify high-potential projects in the
                        ever-changing crypto market, save time and resources.
                      </p>
                      <p className="mt-1 text-break">
                        For further information about the X-Ray tool and to see
                        X-Ray user interface, kindly refer to the{" "}
                        <a href="https://docs.visionscan.ai/key-visionscan-ai-tool-features/project-scanner-x-ray">X-Ray page located in the whitepaper</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img src={wallet} width="100%" alt="..." />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">Wallet</h4>
                      <p className="mt-1 text-break">
                        From tracking your portfolio to making instant
                        transactions, "Wallet" offers a streamlined and
                        user-friendly interface for managing your crypto assets
                        with confidence.
                      </p>
                      <p className="mt-1 text-break">
                      To obtain further details about the Wallet tool and to see Wallet user interface, kindly
                        refer to the{" "}
                        <a href="https://docs.visionscan.ai/key-visionscan-ai-tool-features/wallet-manager-wallet">Wallet page outlined in the whitepaper</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex flex-column flex-md-row">
                    <div className="col d-flex align-items-center">
                      <img src={dashboard} width="100%" alt="..." />
                    </div>
                    <div className="col">
                      <h4 className="mt-3 fw-bold">Dashboard</h4>
                      <p className="mt-1 text-break">
                        With the ability to create a custom dashboard tailored
                        to your individual needs, you can access the most
                        relevant data and insights at a glance, maximizing your
                        returns and minimizing your risks.
                      </p>
                      <p className="mt-1 text-break">
                        For more information on the Dashboard and to see
                        Dashboard user interface, kindly refer to the{" "}
                        <a href="https://docs.visionscan.ai/key-visionscan-ai-tool-features/dashboard">Dashboard page in whitepaper</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-sm">
              <img src={eye} alt="eye" />
            </div>
            <div className="col-sm mt-4 mt-md-0">
              <h2>Eye</h2>
              <p className="lead description">
                With advanced algorithms and machine learning capabilities,
                "Eye" offers a comprehensive view of market trends and portfolio
                performance, helping users to stay ahead of the competition.
              </p>
              <p className="lead description">
                To learn more about the Eye tool, please refer to the Eye page
                located in the whitepaper.
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "100px" }}>
            <div className="col-sm">
              <h2>X-Ray</h2>
              <p className="lead description">
                Whether you are a seasoned investor or a newcomer to the crypto
                world, "X-Ray" is an indispensable tool that will help you to
                identify high-potential projects in the ever-changing crypto
                market, save time and resources.
              </p>
              <p className="lead description">
                For further information about the X-Ray tool, kindly refer to
                the X-Ray page located in our whitepaper.
              </p>
            </div>
            <div className="col-sm mt-4 mt-md-0 d-flex align-items-center">
              <img src={xray} alt="about_img" />
            </div>
          </div>
          <div className="row" style={{ marginTop: "100px" }}>
            <div className="col-sm d-flex align-items-center">
              <img src={wallet} alt="about_img" />
            </div>
            <div className="col-sm mt-4 mt-md-0">
              <h2>Wallet</h2>
              <p className="lead description">
              From tracking your portfolio to making instant transactions, "Wallet" offers a streamlined and user-friendly interface for managing your crypto assets with confidence.
              </p>
              <p className="lead description">
              To obtain further details about the Wallet tool, kindly refer to the Wallet page outlined in the whitepaper.
              </p>
              
            </div>
          </div>
          <div className="row" style={{ marginTop: "100px" }}>
            <div className="col-sm">
              <h2>Dashboard</h2>
              <p className="lead description">
              With the ability to create a custom dashboard tailored to your individual needs, you can access the most relevant data and insights at a glance, maximizing your returns and minimizing your risks.
              </p>
              <p className="lead description">
              For more information on the Dashboard, kindly refer to the Dashboard page in the whitepaper.
              </p>
            </div>
            <div className="col-sm mt-4 mt-md-0 d-flex align-items-center">
              <img src={dashboard} alt="about_img" />
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Features;
