import "../src/assets/scss/css/styles.min.css";
import { Navbar } from "./components/Navbar";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import Features from "./components/Features";
import About from "./components/About";
// import Tokenomics from "./components/Tokenomics";
import { Roadmap } from "./components/Roadmap";
import { Partners } from "./components/Partners";
import { Faq } from "./components/Faq";
import OurApp from "./components/OurApp";

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Features />
      <OurApp/>
      {/* <Tokenomics /> */}
      <Roadmap />
      <Partners />
      <Faq />
      <Footer />
    </>
  );
}

export default App;
