import React from "react";
import { FaCheckCircle } from "react-icons/fa";

export const Roadmap = () => {
  return (
    <>
      <section id="roadmap" className="py-5">
        <div className="container">
          <div className="text-center">
            <p className="subtitle">Process</p>
            <h2>Roadmap</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="main-timeline">
                <div className="timeline left">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6>Q1 2023:</h6>
                      <ul className="mb-0 list-unstyled">
                        <li>
                          <FaCheckCircle id="icon" />
                          Provide early access to AI tools to selected
                          participants
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Launch public beta version for wider audience
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Start marketing campaigns to raise awareness about the
                          product
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          CEX listings
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="timeline right">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6>Q2 2023:</h6>
                      <ul className="mb-0 list-unstyled">
                        <li>
                          <FaCheckCircle id="icon" />
                          Improve user experience and interface of the platform
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Expand features and add new AI analytical tools
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Onboard new partners and integrations
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Launch mobile applications for iOS and Android
                          platforms
                        </li><li>
                          <FaCheckCircle id="icon" />
                          Staking protocol launch
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="timeline left">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6>Q3 2023:</h6>
                      <ul className="mb-0 list-unstyled">
                        <li>
                          <FaCheckCircle id="icon" />
                          Increase user base and user engagement
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Focus on community building and engagement activities
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Enhance security and privacy measures
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="timeline right">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6>Q4 2023:</h6>
                      <ul className="mb-0 list-unstyled">
                        <li>
                          <FaCheckCircle id="icon" />
                          Launch new revenue generating models and services
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Expand globally and target new markets
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Focus on sustainable growth and user acquisition
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="timeline left">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6>Q1 2024:</h6>
                      <ul className="mb-0 list-unstyled">
                        <li>
                          <FaCheckCircle id="icon" />
                          Introduce new AI-powered financial products
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Focus on customer retention and user satisfaction
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="timeline right">
                  <div className="card">
                    <div className="card-body p-4">
                      <h6>Q2 2024:</h6>
                      <ul className="mb-0 list-unstyled">
                        <li>
                          <FaCheckCircle id="icon" />
                          Further expand product offerings
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Focus on product scalability and stability
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Enhance user privacy and security features
                        </li>
                        <li>
                          <FaCheckCircle id="icon" />
                          Initiate partnerships with traditional financial
                          institutions
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
