import React from "react";

export const Faq = () => {
  return (
    <>
      <section id="faq" className="py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2>FAQ</h2>
          </div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  1. What is the Artificial Intelligence (AI)?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Artificial Intelligence (AI) is a type of technology that enables machines to perform tasks that would typically require human intelligence, such as learning from experience, recognizing patterns, and making decisions. It is used in a variety of applications, from personal assistants like Siri and Alexa, to self-driving cars and fraud detection in finance.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  2. What is the VisionScan AI?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  VisionScan AI is an AI-based crypto project that offers users a suite of tools for market analysis, project scanning, and wallet management. It aims to enhance the transparency and efficiency of the crypto finance landscape, providing users with comprehensive insights and support for informed investment decisions.
                </div>
              </div>
            </div>
            {/* <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  3. What is the X-Ray?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  X-Ray is an AI-based tool developed by VisionScan AI that scans the crypto market and provides users with a comprehensive analysis of projects and their potential risks and opportunities. It allows users to make informed investment decisions based on reliable and up-to-date information.
                </div>
              </div>
            </div> */}
            {/* <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  4. What is the Eye?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Eye is an AI-based market analysis tool developed by VisionScan AI team that offers users real-time insights into crypto market trends and patterns. It provides users with accurate data and predictions, empowering them to make informed investment decisions.
                </div>
              </div>
            </div> */}
            {/* <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  5. What is the Wallet?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Wallet is an AI-based tool developed by VisionScan AI team that offers users a secure and user-friendly platform for managing their crypto assets. It allows users to easily store, transfer, and monitor their crypto investments, providing them with peace of mind and control over their finances.
                </div>
              </div>
            </div> */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  3. Why we are unique?
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  VisionScan AI is unique because we offer users a comprehensive suite of AI-based tools for market analysis, project scanning, and wallet management, providing them with reliable and up-to-date information to make informed investment decisions. Our team of experts has extensive experience in both finance and AI, and we are committed to enhancing the transparency and efficiency of the crypto finance landscape. We believe that our unique combination of AI technology, financial expertise, and user-focused approach sets us apart from other crypto projects on the market.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  4. What is the $VSAI?
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  $VSAI is a native token developed by VisionScan AI that serves as a means of payment for accessing our suite of AI-based tools for market analysis, project scanning, and wallet management. It also enables users to participate in our cashback program and DAO, giving them a say in the development and direction of our project. With $VSAI, users can enjoy a seamless and secure experience in managing their crypto assets while also being part of a vibrant and growing community of investors and enthusiasts.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  5. What is our mission?
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Our mission at VisionScan AI is to empower crypto investors with accurate and reliable information and tools that enable them to make informed investment decisions. We believe that AI technology has the potential to transform the finance industry, and we are committed to leveraging this technology to enhance transparency, efficiency, and security in the crypto finance landscape. Through our suite of AI-based tools and our user-focused approach, we aim to make crypto investing accessible and rewarding for everyone.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
