import React from "react";

const Header = () => {
  return (
    <>
      <section id="header">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="subtitle">VisionScan AI</p>
              <h1>
                Embark on a journey to a new world of limitless crypto
                possibilities with VisionScan AI. Future is here.
              </h1>
              <p className="lead description">
                Our team of accomplished experts is dedicated to give you an
                opportunity to embrace the future of crypto research with
                VisionScan AI, where cutting-edge AI technology meets unrivaled
                market analysis for an unparalleled experience.
              </p>
              <div className="d-flex flex-column flex-md-row">
                <a href="https://t.me/visionscanai">
                  <button className="btn btn-lg me-2 mb-3 mb-md-0">
                    Our community
                  </button>
                </a>
                <a href="/">
                  <button className="btn btn-lg">Participate in Presale</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
