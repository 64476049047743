import React from "react";
import app1 from "../assets/images/app1.png";
import app2 from "../assets/images/app2.png";
import app3 from "../assets/images/app3.png";
import app4 from "../assets/images/app4.png";
import app5 from "../assets/images/app5.png";

const OurApp = () => {
  return (
    <>
      <section className="py-5" id="ourapp">
        <div className="text-center mb-5">
          <p className="subtitle">App</p>
          <h2 className="mb-4">VisionScan App</h2>
          <p>
            The VisionScan AI mobile app allows users to access cutting-edge AI
            technology and services right from their fingertips, anytime and
            anywhere.
          </p>
          <p>
            <a href="https://docs.visionscan.ai/visionscan-ai-app" className="text-decoration-none" style={{color:"#db97fa"}}>
              To discover the user interface of our mobile app, please navigate
              to the App section in the whitepaper by clicking here.
            </a>
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="owl-carousel owl-theme app-carousel">
                <div className="item">
                  <img className="img-fluid" src={app1} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app2} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app3} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app4} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app5} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurApp;
