import React from "react";
import about_img from "../assets/images/about_img.png";
import finance_img from "../assets/images/finance_img.png";
import token_img from "../assets/images/token_utility.png";

const About = () => {
  return (
    <>
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <img src={about_img} alt="about_img" />
            </div>
            <div className="col-sm mt-4 mt-md-0">
              <p className="subtitle">About Us</p>
              <h2>VisionScan AI</h2>
              <p className="lead description">
                At VisionScan AI, we're passionate about using the latest
                advancements in artificial intelligence and machine learning to
                revolutionize the world of crypto investing. Our team of
                experienced developers and crypto experts has created a suite of
                tools that provide real-time market insights and analysis,
                enabling investors to make informed decisions and maximize their
                returns.
              </p>
              <p className="lead description">
                From the EYE market analysis tool to the X-Ray project scanner
                and Wallet management tool, our cutting-edge technology and
                user-friendly interfaces are designed to make crypto investing
                accessible to everyone.
              </p>
              <a
                href="https://docs.visionscan.ai/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-lg">Whitepaper</button>
              </a>
            </div>
          </div>
          <div className="row" style={{ marginTop: "100px" }}>
            <div className="col-sm">
              <p className="subtitle">Finance</p>
              <h2>What advantages does VisionScan AI offer to you?</h2>
              <p className="lead description">
                Democratizing access to investment opportunities: With its suite
                of user-friendly tools, VisionScan AI aims to make crypto
                investing accessible to everyone. By using AI to provide
                real-time market analysis and insights, the platform empowers
                users to make informed decisions and take advantage of new
                investment opportunities.
              </p>
              <p className="lead description">
                Increasing transparency and trust: One of the biggest challenges
                in the crypto world is the lack of transparency and trust.
                VisionScan AI addresses this issue by using advanced algorithms
                and machine learning to provide a comprehensive view of the
                crypto market and individual projects. This enables investors to
                make more informed decisions and reduces the risk of fraud and
                scams.
              </p>
              <p className="lead description">
                Advancing the use of AI in finance: VisionScan AI is at the
                forefront of using AI and machine learning to enhance the
                efficiency and accuracy of financial analysis. As the platform
                grows and evolves, it has the potential to become a model for
                how AI can be used to improve finance and investing.
              </p>

              <a
                href="https://docs.visionscan.ai/usdvsa-features"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-lg">Read More</button>
              </a>
            </div>
            <div className="col-sm mt-4 mt-md-0 d-flex align-items-center">
              <img src={finance_img} alt="about_img" />
            </div>
          </div>
          <div className="row" style={{ marginTop: "100px" }}>
            <div className="col-sm d-flex align-items-center">
              <img src={token_img} alt="about_img" />
            </div>
            <div className="col-sm mt-4 mt-md-0">
              <p className="subtitle">Token Utility</p>
              <h2>$VSAI</h2>
              <p className="lead description">
                As a holder of the VisionScan AI token ($VSAI), you gain
                exclusive access to a wide range of features and services
                provided by the VisionScan AI platform. These include advanced
                tools such as "Eye" and "X-Ray" for market and project analysis
                and the "Wallet" for secure storage of your cryptocurrencies. In
                addition, $VSAI tokens can be used to pay for the services
                provided by VisionScan AI, as well as earn cashback rewards
                through our loyalty program.
              </p>
              <p className="lead description">
                As part of our commitment to decentralization, $VSAI token
                holders can also participate in our DAO (Decentralized
                Autonomous Organization), where they can vote on key decisions
                affecting the future of the platform. And for those looking to
                further support the growth of the VisionAI ecosystem, $VSAI
                tokens can be staked to earn additional rewards.
              </p>
              <p className="lead description">
                By owning $VSAI tokens, you become an integral part of the
                VisionScan AI community, gaining access to exclusive features
                and helping to shape the future of the platform. Join us on this
                exciting journey towards a more advanced and decentralized
                financial future.
              </p>
              <a href="/" target="_blank">
                <button className="btn btn-lg">Buy on Presale</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
