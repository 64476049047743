import React from "react";
import logo from "../assets/images/logo.png";

export const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} width="100px" alt="logo" />
          </a>
          <button
            className="navbar-toggler bg-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link" href="https://docs.visionscan.ai/">
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Buy on Presale
                </a>
              </li>
            </ul>
            {/* <ul className="list-unstyled d-flex mt-2">
              <li className="me-3">
                <a className="link-dark" href="/">
                  <FaTelegram style={{ fontSize: "2rem", color: "white" }} />
                </a>
              </li>
              <li>
                <a className="link-dark" href="/">
                  <FaTwitter style={{ fontSize: "2rem", color: "white" }} />
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </>
  );
};
