import React from "react";
// import p6 from "../assets/images/p6.png";
// import p7 from "../assets/images/p7.png";
// import p8 from "../assets/images/p8.png";
// import p11 from "../assets/images/p11.png";
// import p10 from "../assets/images/p10.png";
import p1 from "../assets/images/p1.png";
import p2 from "../assets/images/p2.png";
// import p3 from "../assets/images/p3.png";
// import p4 from "../assets/images/p4.png";
// import p5 from "../assets/images/p5.png";

export const Partners = () => {
  return (
    <>
      <div className="py-5" id="partners">
        <div className="container">
        <div className="text-center mb-5">
            <h2>Partners</h2>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="item me-3">
                <a href="/">
                  <img className="img-fluid" src={p1} alt=".." />
                </a>
              </div>
              <div className="item">
                <a href="https://dexview.com/">
                  <img className="img-fluid" src={p2} alt=".." />
                </a>
              </div>
              {/* <div className="owl-carousel owl-theme partners-carousel">
                                <div className="item"> <a href="/"><img className="img-fluid" src={p6} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p7} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p8} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p11} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p10} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p1} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p2} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p3} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p4} alt="#" /></a></div>
                                <div className="item"> <a href="/"><img className="img-fluid" src={p5} alt="#" /></a></div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
