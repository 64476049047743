import React from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import logo from "../assets/images/logo.png";

export const Footer = () => {
  return (
    <>
      <footer>
        <section className="py-4" style={{ backgroundColor: "#292e41" }}>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-3 d-flex align-items-center" >
              <div className="d-flex col">
                <a href="/" className="mb-3 mb-md-0">
                  <img src={logo} width="50%" alt="logo" />
                </a>
              </div>

              <ul className="nav d-flex col list-unstyled">
                <li className="nav-item mb-2"><a href="/" className="nav-link">VisionScan AI</a></li>
                <li className="nav-item mb-2"><a href="https://docs.visionscan.ai/" className="nav-link">Docs</a></li>
                <li className="nav-item mb-2"><a href="https://docs.visionscan.ai/security-and-smart-contract" className="nav-link">Security</a></li>
                <li className="nav-item mb-2"><a href="https://docs.visionscan.ai/usdvsai-features" className="nav-link">$VSAI</a></li>
              </ul>

              <ul className="d-flex col justify-content-start justify-content-md-center list-unstyled">
                <li className="me-3">
                  <a href="https://t.me/visionscanai">
                    <FaTelegram style={{ fontSize: "1.5rem", color: "white" }} />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/VisionScanAI">
                    <FaTwitter style={{ fontSize: "1.5rem", color: "white" }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <section className="py-5" style={{ backgroundColor: "#383d51" }}>
        <div className="container">

          <div className="row">
            <div className="col-12 col-md-4 mb-3">
              <ul className="nav flex-column">
                <ul className="list-unstyled d-flex mt-2">
                  <li className="me-3">
                    <a href="/">
                      <FaTelegram style={{ fontSize: "1.5rem", color: "white" }} />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <FaTwitter style={{ fontSize: "1.5rem", color: "white" }} />
                    </a>
                  </li>
                </ul>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">$VSAI</a></li>
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Roadmap</a></li>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Whitepaper</a></li>
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Tokenomics</a></li>
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Presale</a></li>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">X-ray</a></li>
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Eye</a></li>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Dashboard</a></li>
                <li className="nav-item mb-2"><a href="/" className="nav-link p-0">Wallet</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      </footer>
    </>
  );
};
